<template>
	<v-container fill-height>
		<div class="text-center">
			<v-icon
				class="text-h2"
				color="success"
			>mdi-check-circle</v-icon>
			
			<div class="mt-3">
				<span color="success2">회원가입</span>이 완료되었습니다
			</div>
			
			<v-card
				class="mt-10"
				elevation="5"
			>
				<v-card-text
					class="text-left"
				>
					<div>{{ user.name }}님 회원가입을 진심으로 축하드립니다 </div>
					<div>회원님의 아이디는 <span>{{ user.id }}</span>입니다</div>
				</v-card-text>
			</v-card>
			
			<v-row
				class="pa-0 ma-0 mt-5"
				align="center"
			>
				<v-col
					cols="6"
				>
					<v-btn
						block
						color="success"
						:to="'/Login'"
					>
						로그인
					</v-btn>
				</v-col>
				
				<v-col
					cols="6"
				>
					<v-btn
						block
						outlined
						:to="'/Home'"
					>
						메인으로 가기
					</v-btn>
				</v-col>
				
			</v-row>
		</div>
	</v-container>
</template>

<script>
	export default{
		name: 'SignUpResult'
		,props: []
		,data: function(){
			return {
				user: {
					id: 'heestar99'
					,name: '립페이'
				}
			}
		}
	}
</script>







